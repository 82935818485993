
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import {EventHandlerUtil} from "@/assets/ts/_utils";
import $ from 'jquery'
import KTCreateAccountModal from "@/components/modals/wizards/CreateAccountModal.vue";

export default defineComponent({
  name: "asset-overview",
  components: {
    KTCreateAccountModal
  },
  props: {
    //assetId: String
  },
  methods: {
    showClaimsTab(e) {
      e.preventDefault();

      //this.$refs.claimsTab[0].click();
      //$("a[href='#fm_claims']").trigger("mousedown");
      //const targetElement = this.$refs.claimsTab as HTMLElement;
      //EventHandlerUtil.trigger(targetElement, "click");
      $(".fm_tab_el").removeClass("active");
      $("a[href='#fm_claims']").addClass("active");
      $(".tab-pane.active").removeClass("active show");
      $("#fm_claims").addClass("active show");
    },
    showMaintenanceTab(e) {
      e.preventDefault();
      $(".fm_tab_el").removeClass("active");
      $("a[href='#fm_maintenance_records']").addClass("active");
      $(".tab-pane.active").removeClass("active show");
      $("#fm_maintenance_records").addClass("active show");
      //$("a[href='fm_maintenance_records']").trigger("mousedown");
      //const targetElement = document.querySelector("a[href='#fm_maintenance_records']") as HTMLElement;
      //EventHandlerUtil.trigger(targetElement, "click");
    },
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/polarus/gcz/fm/short/"+this.assetId)
          .then(data => {
            console.log(data);
            //update project tabs table
            //this.title = "Assets details filled with AJAX data";
            this.$nextTick(() => {
              this.shortInfo = data.data.data;
              //console.log(this.shortInfo);
              setCurrentPageTitle(this.shortInfo.title);
              setCurrentPageBreadcrumbs(this.shortInfo.title, ["Sayğac reestri"]);
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            console.log(response);
          });
    }
  },
  created() {
    const route = useRoute();
    this.assetId = route.params.assetId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  setup() {
    onMounted(() => {
      //setCurrentPageToolbarType("assetsList");
    });
  },
  data: function() {
    let assetId: any;
    let shortInfo: any;
    const dataLoaded = false;
    return {assetId, shortInfo, dataLoaded}
  }
});
