<template>
  <!--begin::Modal - Create account-->
  <div
    class="modal fade"
    id="kt_modal_create_account"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-1000px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Title-->
          <h2>Şikayət əlavə etmək</h2>
          <!--end::Title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y m-5">
          <!--begin::Stepper-->
          <div
            ref="createAccountRef"
            class="stepper stepper-links d-flex flex-column"
            id="kt_create_account_stepper"
          >
            <!--begin::Nav-->
            <div class="stepper-nav py-5">
              <!--begin::Step 2-->
              <div class="stepper-item current" data-kt-stepper-element="nav">
                <h3 class="stepper-title">
                  Şikayət forması
                </h3>
              </div>
              <!--end::Step 2-->
            </div>
            <!--end::Nav-->

            <!--begin::Form-->
            <form
              class="mx-auto mw-600px w-100 py-10"
              novalidate="novalidate"
              id="kt_create_account_form"
              @submit="handleStep"
            >
              <!--begin::Step 2-->
              <div class="current" data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                  <!--begin::Input group-->
                  <div class="mb-10 fv-row">
                    <!--begin::Label-->
                    <label class="form-label mb-3">Adınız və soyadınız</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      name="nameSurname"
                      placeholder=""
                      value=""
                    />
                    <ErrorMessage
                      class="fv-plugins-message-container invalid-feedback"
                      name="nameSurname"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="mb-10 fv-row">
                    <!--begin::Label-->
                    <label class="form-label mb-3">Telefon nömrəniz</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        name="phoneNumber"
                        placeholder=""
                        value=""
                    />
                    <ErrorMessage
                        class="fv-plugins-message-container invalid-feedback"
                        name="phoneNumber"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-10 fp-row">
                    <label class="fs-6 fw-bold mb-2">Şikayətin məzmunu</label>

                      <el-input
                          type="textarea"
                          rows="3"
                          name="claimText"
                          placeholder="Şikayətinizi ətraflı qeyd edin"
                      />
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Step 2-->
              <!--begin::Step 5-->
              <div data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                  <!--begin::Heading-->
                  <div class="pb-8 pb-lg-10">
                    <!--begin::Title-->
                    <h2 class="fw-bolder text-dark">Şikayətiniz göndərildi!</h2>
                    <!--end::Title-->

                  </div>
                  <!--end::Heading-->

                  <!--begin::Body-->
                  <div class="mb-0">
                    <!--begin::Text-->
                    <div class="fs-6 text-gray-600 mb-5">
                      Writing headlines for blog posts is as much an art as it
                      is a science and probably warrants its own post, but for
                      all advise is with what works for your great & amazing
                      audience.
                    </div>
                    <!--end::Text-->

                    <!--begin::Alert-->
                    <div
                      class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
                    >
                      <!--begin::Icon-->
                      <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <inline-svg
                          src="media/icons/duotone/Code/Warning-1-circle.svg"
                        />
                      </span>
                      <!--end::Icon-->
                      <!--begin::Wrapper-->
                      <div class="d-flex flex-stack flex-grow-1">
                        <!--begin::Content-->
                        <div class="fw-bold">
                          <h4 class="text-gray-800 fw-bolder">
                            We need your attention!
                          </h4>
                          <div class="fs-6 text-gray-600">
                            To start using great tools, please, please
                            <a href="#" class="fw-bolder"
                              >Create Team Platform</a
                            >
                          </div>
                        </div>
                        <!--end::Content-->
                      </div>
                      <!--end::Wrapper-->
                    </div>
                    <!--end::Alert-->
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Step 5-->

              <!--begin::Actions-->
              <div class="d-flex flex-stack pt-15">
                <!--begin::Wrapper-->
                <div class="me-2">
                  <button
                    type="button"
                    class="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    @click="previousStep()"
                  >
                    <span class="svg-icon svg-icon-3 me-1">
                      <inline-svg
                        src="media/icons/duotone/Navigation/Left-2.svg"
                      />
                    </span>
                    Geri
                  </button>
                </div>
                <!--end::Wrapper-->

                <!--begin::Wrapper-->
                <div>
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary"
                    v-if="currentStepIndex === totalSteps - 1"
                    @click="formSubmit()"
                  >
                    <span class="indicator-label">
                      Göndərmək
                      <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg
                          src="icons/duotone/Navigation/Right-2.svg"
                        />
                      </span>
                    </span>
                    <span class="indicator-progress">
                      Gözləyin...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </button>

                  <button type="submit" class="btn btn-lg btn-primary" v-else>
                    Davam etmək
                    <span class="svg-icon svg-icon-3 ms-1 me-0">
                      <inline-svg
                        src="media/icons/duotone/Navigation/Right-2.svg"
                      />
                    </span>
                  </button>
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Stepper-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create project-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

interface Step1 {
  accountType: string;
}

interface Step2 {
  teamSize: string;
  teamAccountName: string;
  accountPlan: string;
}

interface Step3 {
  businessName: string;
  shortenedDescriptor: string;
  corporationType: string;
  businessDescription: string;
  contactEmail: string;
}

interface Step4 {
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-account-modal",
  components: {
    Field,
    ErrorMessage
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<KTCreateApp>({
      accountType: "personal",
      teamSize: "1-1",
      teamAccountName: "",
      accountPlan: "1",
      businessName: "Keenthemes Inc.",
      shortenedDescriptor: "KEENTHEMES",
      corporationType: "1",
      businessDescription: "",
      contactEmail: "corp@support.com",
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      expirationMonth: "1",
      expirationYear: "2022",
      cvv: "123"
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>();

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit(values => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const formSubmit = () => {
      console.log(formData.value);

      Swal.fire({
        text: "Şikayətiniz göndərildi. Təşəkkür edirik!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Geri qayıtmaq",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary"
        }
      }).then(() => {
        window.location.reload();
      });
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData
    };
  }
});
</script>
