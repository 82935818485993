<template>
  <!--begin::Navbar-->
  <div :class="[!dataLoaded && 'overlay overlay-block']" class="card mb-5 mb-xxl-8">
    <div class="card-body pt-9 pb-0 ps-3 pe-3 ps-sm-7 pe-sm-7">
      <div
          class="overlay-layer rounded bg-white bg-opacity-5"
          :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!--begin::Details-->
      <div class="flex-wrap flex-sm-nowrap mb-3" :class="{'d-flex': dataLoaded, 'd-none': !dataLoaded }" v-if="dataLoaded">
        <!--begin: Pic-->
        <div class="me-7 mb-4 w-100 w-sm-auto text-center">
          <div
              class="symbol symbol-sm-100px symbol-200px symbol-lg-160px symbol-fixed position-relative ribbon"
          >
            <img :src="`${shortInfo.photos[0].sm}`" alt="" />
            <div
                class="position-absolute translate-middle bottom-0 start-100 mt-2 bg-danger rounded-circle border border-4 border-white h-20px w-20px d-none"
            ></div>
            <div :class="`ribbon-label bg-${shortInfo.condition.class} mt-15`">{{shortInfo.condition.label}}</div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
              class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                    href="#"
                    class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                >{{shortInfo.title}}</a
                >
                <a href="#">
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <inline-svg src="media/icons/duotone/Design/Verified.svg" />
                  </span>
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-4 mb-4 pe-2">
                <a
                    href="#"
                    class="d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="media/icons/duotone/Media/Playlist2.svg" />
                  </span>
                  {{shortInfo.eq_id}}
                </a>
                <a
                    href="#"
                    class="d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                        src="media/icons/duotone/Tools/Screwdriver.svg"
                    />
                  </span>
                  {{shortInfo.manufacturer}}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4">
              <a
                  href="#"
                  class="btn btn-sm btn-danger me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_account"
              >Şikayət əlavə etmək</a
              >
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded min-w-47 py-3 px-4 me-5 me-sm-6 mb-3 min-w-sm-125px d-inline-block d-sm-block"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg
                          src="media/icons/duotone/Devices/Diagnostics.svg"
                      />
                    </span>
                    <div
                        class="fs-4 fs-sm-2 fw-bolder"
                        data-kt-countup="true"
                        :data-kt-countup-value="`${shortInfo.lastMeterReading}`"
                        data-kt-countup-prefix=""
                    >
                      <span v-if="shortInfo.lastMeterReading">{{shortInfo.lastMeterReading}} m³</span>
                      <span v-if="!shortInfo.lastMeterReading">M/D</span>
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-7 fs-sm-6 text-gray-600 ps-8">Son göstərici<br><span class="fs-7 fs-sm-6 text-gray-400">Noyabr 2021</span></div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded min-w-47 py-3 px-4 me-0 me-sm-6  mb-3 min-w-sm-125px d-inline-block d-sm-block"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg
                          src="media/icons/duotone/Interface/Calendar.svg"
                      />
                    </span>
                    <div
                        class="fs-4 fs-sm-2 fw-bolder"
                    >
                      Noyabr 2021
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-7 fs-sm-6 text-gray-600 ps-8">Son təmir</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded min-w-47 py-3 px-4 me-5 me-sm-6 mb-3 min-w-sm-125px d-inline-block d-sm-block"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg
                          src="media/icons/duotone/Tools/Compass.svg"
                      />
                    </span>
                    <div
                        class="fs-4 fs-sm-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="2026"
                        data-kt-countup-prefix="Noyabr "
                    >
                      M/D
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-7 fs-sm-6 text-gray-600 ps-8">İstehsal tarixi</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                    class="border border-gray-300 border-dashed rounded min-w-47 py-3 px-4 me-0 me-sm-6  mb-3 min-w-sm-125px d-inline-block d-sm-block"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg
                          src="media/icons/duotone/Tools/Tools.svg"
                      />
                    </span>
                    <div
                        class="fs-4 fs-sm-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="2026"
                        data-kt-countup-prefix="Noyabr "
                    >
                      Noyabr 2026
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-7 fs-sm-6 text-gray-600 ps-8">Növbəti təmir</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            <div
                class="d-flex align-items-center w-100 w-sm-300px flex-column mt-3"
            >
              <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                <span class="fw-bold fs-6 text-gray-400"
                >Kalibrasiya nəticəsi</span
                >
                <span class="fw-bolder fs-6">{{shortInfo.calibration.label2}}</span>
              </div>

              <div class="h-5px mx-3 w-100 bg-light mb-3">
                <div
                    :class="`bg-${shortInfo.calibration.class} rounded h-5px`"
                    role="progressbar"
                    :style="`width: ${shortInfo.calibration.value}%;`"
                    :aria-valuenow="`${shortInfo.calibration.value}`"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
              </div>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <div class="flex-column flex-md-row rounded border p-10 mb-8" :class="{'d-flex': dataLoaded, 'd-none': !dataLoaded }" v-if="dataLoaded">
        <ul class="nav nav-tabs nav-pills flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-300px">
          <li class="nav-item me-0 mb-md-2">
            <a class="fm_tab_el nav-link active btn btn-flex btn-active-light-primary" data-bs-toggle="tab" role="tab" href="#fm_tech_passport">
              <span class="svg-icon svg-icon-2">
                <inline-svg
                    src="media/icons/duotone/General/Clipboard.svg"
                />
              </span>
              <span class="d-flex flex-column align-items-start">
                  <span class="fs-4 fw-bolder">Texniki Pasport</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a class="fm_tab_el nav-link btn btn-flex btn-active-light-primary" data-bs-toggle="tab" role="tab" href="#fm_maintenance_records">
              <span class="svg-icon svg-icon-2">
                <inline-svg
                    src="media/icons/duotone/Tools/Screwdriver.svg"
                />
              </span>
              <span class="d-flex flex-column align-items-start">
                  <span class="fs-4 fw-bolder">Yoxlama tarixçəsi</span>
              </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="fm_tab_el nav-link btn btn-flex btn-active-light-primary" data-bs-toggle="tab" role="tab" href="#fm_calibration_report">
              <span class="svg-icon svg-icon-2">
                <inline-svg
                    src="media/icons/duotone/Home/Weight2.svg"
                />
              </span>
              <span class="d-flex flex-column align-items-start">
                  <span class="fs-4 fw-bolder">Kalibrasiya nəticələri</span>
              </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="fm_tab_el nav-link btn btn-flex btn-active-light-danger" data-bs-toggle="tab" role="tab" href="#fm_claims">
              <span class="svg-icon svg-icon-2">
                <inline-svg
                    src="media/icons/duotone/Code/Warning-2.svg"
                />
              </span>
              <span class="d-flex flex-column align-items-start">
                  <span class="fs-4 fw-bolder">Şikayətlər ({{ shortInfo.claims }})</span>
              </span>
            </a>
          </li>
        </ul>
        <div class="tab-content w-100" id="myTabContent">
          <div class="tab-pane fade active show" id="fm_tech_passport" role="tabpanel">
            <p>Diafraqmalı termokompensatorlu qaz sayğacı</p>
            <p><strong>BK-G4T</strong></p>
            <p> </p>
            <p><strong>1. Məmulatın təyinatı</strong></p>
            <p>1.1.<strong>BK-G4T</strong> (<strong><em>Təmir edilmiş)</em></strong> tipli diafraqmalı termokompensatorlu qaz sayğacı (bundan  sonra - sayğac) onun daxilindən  keçən qazın (təbii, maye, neft və başqa qeyri aqressiv qazların) həcmini ölçmək  üçün nəzərdə tutulmuşdur.  Tətbiq sahəsi &ndash; mənzil-istismar təsərrüfatları, həmçinin  qazın sərfinin hesablanması  tələb olunan başqa  fəaliyyət sahələri.</p>
            <p>1.2.Sayğac  EN1359:2017 standartinın tələblərinə uyğun istehsal olunub.</p>
            <p> </p>
            <p><strong>Əsas texniki xarakteristikalar </strong></p>
            <p><strong> </strong></p>
            <table class="table table-rounded table-striped border gs-1 gs-sm-7">
              <thead>
                <tr class="fw-bolder fs-6 text-gray-800">
                  <td>
                    <strong>Parametrlərin adı</strong>
                  </td>
                  <td >
                    <strong>ölçü vahidi</strong>
                  </td>
                  <td>
                    <strong>BK-G4T</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td >
                  Sərfiyyat:
                </td>
                <td rowspan="4" valign="middle">
                  m<sup>3</sup>\saat
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td>
                  - maksimal, Q<sub>max</sub>
                </td>
                <td>
                  6
                </td>
              </tr>
              <tr>
                <td>
                  - nominal, Q<sub>nom</sub>
                </td>
                <td >
                  4
                </td>
              </tr>
              <tr>
                <td>
                  - minimal, Q<sub>min</sub>
                </td>
                <td>
                  0,04(0,025)*
                </td>
              </tr>
              <tr>
                <td>
                  Həssaslıq həddi
                </td>
                <td >
                  m<sup>3</sup>\saat
                </td>
                <td>
                  0,008
                </td>
              </tr>
              <tr>
                <td>
                  Maksimum iş təzyiqi
                </td>
                <td>
                  kPa
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr>
                <td>
                  Təzyiq itkisi
                </td>
                <td >
                  Pa
                </td>
                <td >
                  &lt;200
                </td>
              </tr>
              <tr>
                <td>
                  Korpusun daxilində maksimum yol verilən təzyiq
                </td>
                <td>
                  kPa
                </td>
                <td>
                  100
                </td>
              </tr>
              <tr>
                <td>
                  Ətraf mühitin temperatur diapazonu
                </td>
                <td>
                  <sup>o</sup>C
                </td>
                <td >
                  -40 +60
                </td>
              </tr>
              <tr>
                <td>
                  İşçi mühitin temperatur diapazonu
                </td>
                <td>
                  <sup>o</sup>C
                </td>
                <td>
                  -30 +50
                </td>
              </tr>
              <tr>
                <td>
                  Aşağıdakı sərfiyyat diapazonunda yol verilən nisbi xəta həddi:
                </td>
                <td rowspan="3" valign="middle">
                  %
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td>
                  - Q<sub>min</sub>&le;Q&lt;0,1Q<sub>nom</sub>
                </td>
                <td>
                  &plusmn;3
                </td>
              </tr>
              <tr>
                <td>
                  - 0,1Q<sub>nom</sub>&le;Q&le;Q<sub>max</sub>
                </td>
                <td>
                  &plusmn;1,5
                </td>
              </tr>
              <tr>
                <td>
                  Hesablama mexanizminin tutumu
                </td>
                <td>
                  m<sup>3</sup>
                </td>
                <td>
                  99999,999
                </td>
              </tr>
              <tr>
                <td>
                  Ən az ölçmə qiyməti
                </td>
                <td>
                  litr
                </td>
                <td>
                  0,2
                </td>
              </tr>
              <tr>
                <td>
                  Qabarit ölçüləri, D x E x B
                </td>
                <td>
                  mm
                </td>
                <td>
                  204 х 162х 221
                </td>
              </tr>
              <tr>
                <td>
                  Ştuserlər arasında məsafə , A
                </td>
                <td>
                  mm
                </td>
                <td>
                  110
                </td>
              </tr>
              <tr>
                <td>
                  Giriş və çıxış ştuserlərin ölçüləri
                </td>
                <td>
                  G
                </td>
                <td>
                  1 &frac14;&rdquo;  <sup>7/</sup><sub>8</sub><span style='font-family:"MS Mincho";'>&rdquo;</span>
                </td>
              </tr>
              <tr>
                <td>
                  Kütlə
                </td>
                <td >
                  kq
                </td>
                <td >
                  2,0
                </td>
              </tr>
              <tr>
                <td >
                  Orta xidmət müddəti, ən azı
                </td>
                <td >
                  il
                </td>
                <td >
                  10
                </td>
              </tr>
              </tbody>
            </table>
            <p> </p>
            <ul style="list-style-type: disc;">
              <li><strong><em>Sifarişçinin tələbi ilə.</em></strong></li>
            </ul>
            <p> </p>
            <p>İmpuls ötürücüsünün parametrləri:</p>
            <p>- 12 volta qədər gərginlik</p>
            <p>- 10 mА-dan çox olmayaraq elektrik cərəyanı</p>
            <p>- bir impuls qiyməti 0,01 imp\ m<sup>3</sup> </p>
            <p>&ldquo;K&rdquo; nişanı olan sayğaçlar əlavə olaraq ön ödəməli blokları var.</p>
            <p><strong> </strong></p>
            <p>Qabarit və birləşdirici ölçülərin göstərilməsi - Şək.1  </p>
            <p><img src="media/bkg4t.png" class="w-100 w-sm-auto"/></p>
            <p><strong>Şək. 1</strong></p>
            <p><strong>3.</strong> <strong>Komplektlik</strong></p>
            <p> - Qaz sayğacı &ndash; 1 ədəd</p>
            <p> - Pasport &ndash; 1 nüsxə</p>
            <p> - Qablaşdırma qutusu</p>
            <p> - Montaj hissələrinin dəsti (İstehlakçının sifarişi ilə çatdırılır) </p>
            <p><strong>4. Quruluşu və iş prinsipi</strong></p>
            <p>4.1 Sayğac gövdədən,  onun daxilində quraşdırılmış  ölçmə mexanizmindən və  gövdəyə birləşdirilmiş hesablama  qurğusundan ibarətdir. ölçmə  mexanizmi daxilində quraşdırılmış  membranı olan iki  kameradan ibarətdir. Xüsusi qol tipli mexanizm membranın  düz xətt üzrə  hərəkətini fırlanma hərəkətinə  çevirir və bu hərəkət maqnit  muftası vasitəsi ilə  hesablama qurğusuna ötürülür.</p>
            <p>4.2 Sayğaç ölçülən qazın həcm göstəricilərini, onun +20<sup>0</sup>С temperaturuna uyğunlaşdırılmasını təmin edən mexaniki temperatur kompensatoru ilə təchiz edilmişdir<span style="color:black;">. </span></p>
            <p>4.3 Sayğac hesablama mexanizminin əks gedişinə mane olan qurğu ilə təchiz olunub.</p>
            <p>4.4 Sayğac herkon impuls sensoru köməyi ilə, məlumatın uzaqdan ötürülməsi və avtomatik ölçmə sisteminə (AMR) qoşulmaq üçün hazırdı.</p>
            <p><strong>5. Təhlükəsizlik tədbirləri üzrə göstərişlər</strong></p>
            <p>5.1 Sayğac yaxşı havalandırılan yerdə açıq oddan uzaqda (ən azı 1 metr) quraşdırılmalıdır. Açıq havada quraşdırılan sayğac mexaniki təsirlərdən, yağışdan, qardan və birbaşa günəş şüalarinnan qorunmalıdır Sayğacın quraşdırıldığı yer ona sərbəst çıxışı təmin etməlidir.</p>
            <p>5.2 Sayğacın işində hər hansı nasazlıqlar aşkarlandıqda və ya qaz qoxusu gəldikdə dərhal qaz təchizatı kranını bağlayıb mütəxəssis çağırmaq lazımdır.</p>
            <p><strong>6. Sayğacın quraşdırılması</strong></p>
            <p><strong><em>Diqqət!</em></strong><strong> Boru kəmərinin artıq təzyiq altında yoxlanmasını sayğacın quraşdırılmasından əvvəl   həyata keçirmək lazımdır.</strong></p>
            <p><strong>Sayğacın quraşdırılması zamanı qaz kəmərində qaynaq işlərini aparmaq olmaz.</strong></p>
            <p>6.1 Sayğacın quraşdırılması və sökülməsi, eləcə də onun profilaktik xidmətləri yalnız bunun üçün xüsusi razılığı olan təşkilatlar tərəfindən aparılmalıdır.</p>
            <p>6.2 Bu tip sayğac qaz kəmərinin giriş və çıxış elementlərinə dartılma, sıxılma və əyilmə olmadan quraşdırılır və bərkidilir. Montaj prosesi zamanı bütün bərkidici detalların çəkimlərini yoxlamaq lazımdır.</p>
            <p>6.3 Quraşdırıldıqdan sonra, sayğacın və onun qaz kəməri ilə birləşmə yerlərinin sabun məhlulu ilə germetikliyi yoxlanılmalıdır.</p>
            <p><strong>7. Texniki xidmət</strong></p>
            <p>7.1 Sayğaca xüsusi texniki xidmət tələb olunmur. </p>
            <p>7.2 Sayğac sahibi sayğacın səthini sabun və tərkibində alışqan və korroziya maddələri olmayan digər yuyucu məhlulla vaxtaşırı olaraq silərək onu təmiz saxlamalıdır.</p>
            <p>7.3 Sayğacın təmiri istehsalçı zavodda və ya buna icazəsi olan xüsusi təşkilatlarda aparılır. Təmirdən sonra sayğac təhvil-təslim sınaqlarından keçməlidir.</p>
            <p><strong>8. Yoxlamalar arasındakı interval</strong></p>
            <p>8.1 Yoxlamalar arasındakı interval - 10 il.</p>
            <p>8.2 Sayğacın yoxlanmasını yalnız ixtisaslaşdırılmış təşkilatlar və istehsalçı zavod apara bilər.</p>
            <p> </p>
            <p><strong>9. Saxlama və nəql etmə şərtləri</strong></p>
            <p>9.1 Sayğaclar ГОСТ 15150 saxlama şərtləri qrupu üzrə qablaşdırmada saxlanmalıdır. Sayğaclar saxlanılan yerlərin havası fəal maddələr korreziyasına malik olmamalıdır.</p>
            <p>9.2 İqlim faktorlarının təsiri hissəsində nəql etmə şərtləri: ГОСТ 15150 saxlama şərtləri üzrə.</p>
            <p> </p>
            <p><strong>10. Zəmanət öhdəlikləri</strong></p>
            <p>10.1 Zəmanət müddəti cihazın istimara verilmə tarixindən sonra 12 aydı bu şərtlə ki istehsalatdan çıxma zamani 15 ayı keçmir.</p>
            <p>102.2 Satın alınan zamanı saiğacda defekt olarsa, o yenisi ilə əvəz olunur. </p>
            <p>10.3 Sayğacın plombu yerində olmasa və ya zədələnmiş olsa, və onun pasportu təqdim olunmasa, belə saygac təminata qəbul olunmur.</p>
            <p>10.4 Zəmanət zamanında istehsalçıya aıd olmayan səbəblərə görə sıradan çıxarsa sayğaclar təminata aid olumurlar.</p>
            <p> </p>
            <p><strong>11. İstismar zamanı texniki xidmətin uçotu</strong></p>
            <p>11.1 İstismara verildiyi tarix: <strong>Noyabr 2021</strong></p>
            <p>İstismara verilməyə görə məsul şəxsin imzası: <strong>PS-DN-20-458 nömrəli çatdırılma bildirişinə əsasən Azəriqaz İB-nin nümayəndəsi</strong></p>
            <p>11.2 Dövri yoxlama və təmirdən sonra yoxlama haqqında məlumatlar.</p>
            <p><a @click="showMaintenanceTab" href="#" class="fs-5">Yoxlamaların uçot cədvəli</a></p>
            <p><strong> </strong></p>
            <p><strong>12. Şikayət haqqında məlumat</strong></p>
            <p>12.1  Əgər sayğac yanlış istismar və hazırkı pasportda verilmiş göstərişlərə əməl olunmaması, eləcə də daşınma və saxlanma şərtlərinin pozulması səbəbindən istehlakçının təqsiri üzündən sıradan çıxıbsa istehsalçı şikayəti qəbul etmir.</p>
            <p>12.2. İrəli sürülən şikayətlərin uçotu.</p>

            <p><a @click="showClaimsTab" href="#" class="fs-5">Şikayətlərin uçot cədvəli</a></p>
            <p>Sayğacın keyfiyyəti ilə bağlı bütün suallar üzrə istehsalçı müəssisəyə müraciət etmək lazımdır:</p>
            <p>Azərbaycan Respublikası, Gəncə ş., Vəli Xuluflu küç., 1 tel/faks +99422 2551235, email <a href="mailto:abseron@mail.ru">abseron@mail.ru</a>.</p>
            <p> </p>
            <p><strong>13. Qəbul haqqında şəhadətnamə</strong></p>
            <p>BK-G4T <strong>{{shortInfo.eq_id}}</strong> saylı qaz sayğacı EN1359:2017 standartın şərtlərinə cavab verir və istismara yararlıdır.<strong> <br> <em v-if="shortInfo.condition.value === 'used'">Qeyd:Təmir edilmiş</em></strong></p>
            <p><strong> </strong></p>
            <p>Texniki nəzarət xidmətinin nümayəndəsinin imzası və möhürü: <strong>İbrahimova Bahar Novruz qızı</strong></p>
            <p>Tarix: <strong>Noyabr 2021</strong></p>
            <p> </p>
            <p><strong>14. Yoxlama haqqında şəhadətnamə</strong></p>
            <p>Sayğac &ldquo;Azərbaycan Metrologiya İnstitutu&rdquo; Publik Hüquqi Şəxs nümayəndəsi tərəfindən yoxlanıb.                                 </p>
            <p>                                                                </p>
            <p>Yoxlayan şəxsin imzası və möhürü: <strong>İbrahimova Bahar Novruz qızı</strong></p>
            <p>Tarix: <strong>Noyabr 2021</strong></p>
            <p> </p>
          </div>
          <div class="tab-pane fade" id="fm_maintenance_records" role="tabpanel">

            <table class="table table-rounded table-striped border gs-1 gs-sm-7">
              <thead>
                <tr class="fw-bolder fs-6 text-gray-800">
                  <td>Sayğacın nömrəsi</td>
                  <td>Yoxlama növü</td>
                  <td>Yoxlama tarixi</td>
                  <td>Yoxlayan şəxs</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{shortInfo.eq_id}}</td>
                  <td>Periodik yoxlama</td>
                  <td>Noyabr 2021</td>
                  <td>İbrahimova Bahar Novruz qızı</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade" id="fm_calibration_report" role="tabpanel">Kalibrasiya hesabatı sistemə yüklənməyib.</div>
          <div class="tab-pane fade" id="fm_claims" role="tabpanel">
            <table class="table table-rounded table-striped border gs-1 gs-sm-7 w-100">
              <thead>
                <tr class="fw-bolder fs-6 text-gray-800">
                  <td>
                    İrəli sürülmə tarixi
                  </td>
                  <td>
                    Şikayətin qısa məzmunu
                  </td>
                  <td>
                    Görülmüş tədbirlər
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <KTCreateAccountModal></KTCreateAccountModal>
  </div>
  <!--end::Navbar-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import {EventHandlerUtil} from "@/assets/ts/_utils";
import $ from 'jquery'
import KTCreateAccountModal from "@/components/modals/wizards/CreateAccountModal.vue";

export default defineComponent({
  name: "asset-overview",
  components: {
    KTCreateAccountModal
  },
  props: {
    //assetId: String
  },
  methods: {
    showClaimsTab(e) {
      e.preventDefault();

      //this.$refs.claimsTab[0].click();
      //$("a[href='#fm_claims']").trigger("mousedown");
      //const targetElement = this.$refs.claimsTab as HTMLElement;
      //EventHandlerUtil.trigger(targetElement, "click");
      $(".fm_tab_el").removeClass("active");
      $("a[href='#fm_claims']").addClass("active");
      $(".tab-pane.active").removeClass("active show");
      $("#fm_claims").addClass("active show");
    },
    showMaintenanceTab(e) {
      e.preventDefault();
      $(".fm_tab_el").removeClass("active");
      $("a[href='#fm_maintenance_records']").addClass("active");
      $(".tab-pane.active").removeClass("active show");
      $("#fm_maintenance_records").addClass("active show");
      //$("a[href='fm_maintenance_records']").trigger("mousedown");
      //const targetElement = document.querySelector("a[href='#fm_maintenance_records']") as HTMLElement;
      //EventHandlerUtil.trigger(targetElement, "click");
    },
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/polarus/gcz/fm/short/"+this.assetId)
          .then(data => {
            console.log(data);
            //update project tabs table
            //this.title = "Assets details filled with AJAX data";
            this.$nextTick(() => {
              this.shortInfo = data.data.data;
              //console.log(this.shortInfo);
              setCurrentPageTitle(this.shortInfo.title);
              setCurrentPageBreadcrumbs(this.shortInfo.title, ["Sayğac reestri"]);
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            console.log(response);
          });
    }
  },
  created() {
    const route = useRoute();
    this.assetId = route.params.assetId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  setup() {
    onMounted(() => {
      //setCurrentPageToolbarType("assetsList");
    });
  },
  data: function() {
    let assetId: any;
    let shortInfo: any;
    const dataLoaded = false;
    return {assetId, shortInfo, dataLoaded}
  }
});
</script>
